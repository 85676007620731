<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs
      :routes="[
        { name: 'Inicio' },
        { name: 'Producto' },
        { name: 'Item' },
        { name: 'Listado de imágenes' }
      ]"
    />
    <v-card style="border-radius:10px 10px 0 0;">
      <v-toolbar card style="border-radius:10px 10px 0 0;" color="primary" dark>
        <v-toolbar-title> {{ nombreProducto }} -{{ nombre }}</v-toolbar-title>
      </v-toolbar>
      <v-bottom-nav :active.sync="activeBtn" color="transparent" :value="true">
        <v-btn :to="{ name: 'EditItem', params: { id: $route.params.id } }" flat>
          <span>Informacion</span>
          <v-icon>assignment</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListaPrecios', params: { id: $route.params.id } }" flat>
          <span>Precios</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ItemDimension', params: { id: $route.params.id } }" flat>
          <span>Peso</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListItemImagen', params: { id: $route.params.id } }" flat color="teal">
          <span>Imágenes</span>
          <v-icon>description</v-icon>
        </v-btn>
      </v-bottom-nav>
      <br />
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Listado de Imágenes</v-toolbar-title>
        <v-spacer />
        <v-btn v-if="itemImagenes.length == 0" :to="{ name: 'CreateImagenItem', params: { id: $route.params.id } }" color="primary">
          Agregar Imagen
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-data-table
          :headers="[{ text: 'imagen' }, { text: 'Nombre' }, { text: 'Info adicional' }, { text: 'Acciones' }]"
          :items="lista"
          :rows-per-page-items="[10, 25, 35, 50]"
          class="elevation-1"
        >
          <tr slot="items" slot-scope="props">
            <td class="px-3">
              <img :src="props.item.imagen_path" alt="imagen" style="width:70px; height:70px;" />
            </td>
            <td class="px-3">
              {{ props.item.nombre }}
            </td>
            <td class="px-3">
              {{ props.item.comentario }}
            </td>
            <td class="px-3">
              <v-btn
                class="ma-0"
                small
                icon
                flat
                color="error"
                @click="openModalDeleteItemImagen(props.item)"
              >
                <v-icon small>
                  delete
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </v-data-table>
      </v-container>
      <center>
        <v-btn
            color="error"
            @click="$router.push({ name: 'ListadoProductItems', params: { id: id } })"
          >
            Volver
          </v-btn>
      </center>
    </v-card>
    <ModalDelete @onChange="getImage"/>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Listado de imágenes" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/items/ModalDeleteImagen")
  },
  data() {
    return {
      activeBtn: 3,
      lista: [],
      nombre: "",
      nombreProducto: "",
      id: ""
    };
  },

  computed: {
    ...mapState({
      itemImagenes: state => state.items.itemImagenes
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  async mounted() {
    await this.getItem({ itemId: this.$route.params.id }).then(response => {
      const itemInfo = response.data.data;
      this.nombre = itemInfo.nombre;
      this.id = itemInfo.producto_id;
    });
    await this.getImages({ itemId: this.$route.params.id });
    if (this.itemImagenes.imagen_path != null) {
      this.lista.push(this.itemImagenes);
    }
    await this.getProducto({ productoId: this.id }).then(response => {
      this.tipo = response.data.data.tipo;
      this.nombreProducto = response.data.data.nombre;
    });
  },
  methods: {
    ...mapActions({
      getImages: "items/getItemImages",
      getItem: "items/getItem",
      getProducto: "productos/getProducto",
      replaceShowModalDeleteItemImagen: "items/replaceShowModalDeleteItemImagen",
      replaceCurrentItemImagen: "items/replaceCurrentItemImagen"
    }),
    async getImage() {
       await this.getImages({ itemId: this.$route.params.id });
        this.lista = [];
        if (this.itemImagenes.imagen_path != null) {
          this.lista.push(this.itemImagenes);
        }
    },
    openModalDeleteItemImagen(itemImagen) {
      this.replaceCurrentItemImagen({ itemImagen });
      this.replaceShowModalDeleteItemImagen({ status: true });
    }
  }
};
</script>
